export default {
  credenciadorErrors () {
    const errors = []
    if (!this.$v.lancamentoFinanceiro.credenciador.value.$dirty) return errors
    !this.$v.lancamentoFinanceiro.credenciador.value.required && errors.push('Credenciador é obrigatório')
    return errors
  },

  typeOperationErrors () {
    const errors = []
    if (!this.$v.lancamentoFinanceiro.typeOperation.$dirty) return errors
    !this.$v.lancamentoFinanceiro.typeOperation.required && errors.push('Tipo de operação é obrigatório')
    return errors
  },

  valueErrors () {
    const errors = []
    if (!this.$v.lancamentoFinanceiro.value.$dirty) return errors
    !this.$v.lancamentoFinanceiro.value.required && errors.push('Valor é obrigatório')
    !this.$v.lancamentoFinanceiro.value.minValue && errors.push('Valor mão pode ser negativo')
    return errors
  }
}
