<template>
  <v-form>
    <v-card-title class="modal-cadastro-titulo text-center">
      Lançamento Financeiro
    </v-card-title>

    <v-card-text class="modal-cadastro-form">
      <v-row>
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
          <autoCompleteEntities
            :label="getEntityType(3)"
            :entityProps.sync="lancamentoFinanceiro.credenciador"
            :typesEntities="['3']"
            :disabled="arrayLancamentoFinanceiroIndividual.length > 0"
            :error-messages="credenciadorErrors"
          />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
          <v-autocomplete
            :items="selectsTiposOperacoes" 
            label="Tipos de operações" 
            v-model="lancamentoFinanceiro.typeOperation"
            :error-messages="typeOperationErrors"
            return-object
            dense
            :loading="loadingTiposOperacoes" />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3">
          <v-currency-field
            v-model="lancamentoFinanceiro.value"
            label="Valor"
            prefix="R$"
            :error-messages="valueErrors"
            dense/>
        </v-col>
      </v-row>
    </v-card-text>     

    <v-card-actions class="modal-cadastro-footer">
      <v-btn :color="variables.colorPrimary" @click.prevent="submit" class="br-btn" :loading="loading">Próximo</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import validacao from './validacaoLancamentoFinanceiro'
const namespaceStore = 'lancamentoFinanceiro'


/**
 * MIXIN
 */
import modalCadastro from '@/views/mixins/modalCadastro'
// import autocomplete from '@/views/mixins/autocomplete'


export default {
  name: 'ItensTabsModalLancamentoFinanceiro',
  mixins: [validationMixin, modalCadastro],
  // directives: {mask},
  props: {
    
  },
  components: { 
    autoCompleteEntities: () => import('@/views/components/autoCompleteEntities/lancamentoFinanceiro')

   },
  data: () => ({
    date: null,
    dateFormatted: null,
    menuDate: false,
    activeModal: false,
    searchText: null,
    isLoadingAutoComplete: false,
    modelAutoComplete: null,
    loadingTiposOperacoes: false,

    selectsTiposOperacoes: [
      {
        text: 'Caixa',
        value: ['A.R.C.CC', 'A.R.C.CD']
      },{
        text: 'Pagamentos',
        value: ['A.R.PGTR']
      }
    ],

    headers: [
      { class: 'table-header', text: 'Estabelecimento', value: 'estabelecimento.name' },
      { class: 'table-header', text: 'Tipo Operacao', value: 'typeOperation' },
      { class: 'table-header', text: 'Valor', value: 'value' },
      { class: 'table-header', text: '', value: 'action', sortable: false },
    ]
  }),

  watch: {
    // 'lancamentoFinanceiro.value' (val) {
    //   if (parseFloat(val) < 0) this.lancamentoFinanceiro.value = val * -1;
    // }
  },

  mounted () {
    this.$emit('validacao', this.$v.lancamentoFinanceiro)
    // this.loadingTiposOperacoes = true
    // this.getItensTiposOperacoes()
    //   .finally(() =>  this.loadingTiposOperacoes = false)
  },

  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters(namespaceStore, ['lancamentoFinanceiro', 'lancamentoFinanceiroIndividual', 'item', 'arrayLancamentoFinanceiroIndividual']),
    // ...mapGetters('tiposOperacoes', {
    //   listaItensSelectsTiposOperacoes: 'listaItensSelect'
    // }),
    ...mapGetters('entities', ['listaItensSelect']),
    ...validacao,
  },

  methods: {
   ...mapActions(namespaceStore, ['cadastrarItem', 'limparItem', 'getItens', 'editarItem', 'addLancamentoFinanceiroIndividual', 'setLancamentoParaEditar', 'removeLancamentoParaEditar']),
    ...mapActions('entities', {
      obterItensSelect: 'obterItens'
    }),
    // ...mapActions('tiposOperacoes', {
    //   getItensTiposOperacoes: 'getItens'
    // }),

    submit () {
      this.$emit('proximo', this.$v.lancamentoFinanceiro)
    },
    
    add () {
      this.addLancamentoFinanceiroIndividual(this.lancamentoFinanceiro)
    },

    editar (item, index) {
      this.setLancamentoParaEditar({ item, index })
    },

    deletar (item, index) {
      this.removeLancamentoParaEditar(index)
    }
  },

  destroyed () {
  },

  validations () {
    return  {
      lancamentoFinanceiro: {
        // entity: { required },
        credenciador: { 
          value: {
            required
          }
         },
        typeOperation: { required },
        value: { 
          required,
          // minValue: minValue(0)
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>