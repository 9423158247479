<template>
  <Modal :activeModal="activeModal">
    <template v-slot:btnActivator>
      <v-btn
        tile
        :color="variables.colorPrimary"
        dark class="mb-2 modal-cadastro-btn-cadastrar"
        @click="openModal"
        :loading="loading"
        v-if="permiteAcao($route, 'add')">Novo cadastro</v-btn>
    </template>
    
    <v-card>
      <v-toolbar flat class="modal-cadastro-toolbar">
          Lançamento Financeiro
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-container>
        <div class="abas" width="100%">
          <v-tabs
            v-model="tab"
            background-color="#FFF"
            height="30px">
            <v-tab href="#tab-1" class="abas-item">
              Cadastro de Caixa
            </v-tab>

            <v-tab href="#tab-2" class="abas-item" :disabled="validacaolocal.$invalid" @click.prevent="getEntitiesForDetalhamento">
              Detalhamento de Lançamento
            </v-tab>
          </v-tabs>
        </div>
      </v-container>

      <v-col class="d-flex justify-center align-center" style="min-height: 300px; width: 100%;" v-if="loadingModal">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          />
        </v-col>
      
      <v-container v-else>
        <v-tabs-items v-model="tab" touchless>
          <v-tab-item
            :key="1"
            :value="'tab-1'">
            <lancamentoFinanceiro @proximo="proximo" @validacao="validacao" />
          </v-tab-item>

          <v-tab-item
            :key="2"
            :value="'tab-2'">
            <lancamentoFinanceiroIndividual @validacao="validacaoIndividual" @salvarDados="submit" :loadingBtn="loadingBtn" />
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-card>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import Events from '@/core/service/events'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
// import variables from '@/assets/styles/helpers/_variables.scss'
import moment from 'moment'
// import VCurrencyField from '@/views/components/vCurrencyField'
import lancamentoFinanceiroIndividual from './itensTabsModal/lancamentoFinanceiroIndividual'
import lancamentoFinanceiro from './itensTabsModal/lancamentoFinanceiro'

const namespaceStore = 'lancamentoFinanceiro'


/**
 * MIXIN
 */
import modalCadastro from '@/views/mixins/modalCadastro'
// import autocomplete from '@/views/mixins/autocomplete'


export default {
  name: 'ModalLancamentoFinanceiros',
  mixins: [validationMixin, modalCadastro],

  components: {
    // VCurrencyField,
    // autoCompleteEntities: () => import('@/views/components/autoCompleteEntities')
    lancamentoFinanceiroIndividual,
    lancamentoFinanceiro,
    Modal: () => import('@/views/components/modal')
  },

  data: () => ({
    date: null,
    dateFormatted: null,
    menuDate: false,
    activeModal: false,
    searchText: null,
    isLoadingAutoComplete: false,
    modelAutoComplete: null,
    tab: 'tab-1',
    loadingModal: false,
    loadingBtn: false,
    validacaolocal: {
      credenciador: {}
    },

    selectsTiposOperacoes: [
      {
        text: 'Caixa Crédito',
        value: 'CC'
      },{
        text: 'Caixa Débito',
        value: 'CD'
      }
    ],

    validacaoIndividualLocal: {},

    headers: [
      { class: 'table-header', text: 'Estabelecimento', value: 'estabelecimento.name' },
      { class: 'table-header', text: 'Tipo Operacao', value: 'typeOperation' },
      { class: 'table-header', text: 'Valor', value: 'value' },
      { class: 'table-header', text: '', value: 'action', sortable: false },
    ]
  }),

  watch: {
    'lancamentoFinanceiro.date' (val) {
      this.dateFormatted = this.formatDate(val)
    }
  },

  mounted () {
    Events.$on('cadastro::openModalCadastro', async e => {
      this.setItem(e)
      this.activeModal = true
      this.loadingModal = true

      const arrayIdsEntities = []
      const arrayIdsEntryType = []
      e.params.data.forEach(item => {
        if (item.entity && item.entity.id >= 0) {
          arrayIdsEntities.push(item.entity.id)
        }

        if (item.entryType && item.entryType.id) {
          arrayIdsEntryType.push(item.entryType.id)
        }
      })

      const entities = await this.getEntities({ _filter: { 'id_In':  arrayIdsEntities.join(',') } })
        
      const entryTypes = await this.getItensEntryTypes({ _addlFilter: { 'id_In':  arrayIdsEntryType.join(',') } })
      
      this.arrayLancamentoFinanceiroIndividual.forEach(item => {
        const entity = entities.resultMap.filter(subItem => subItem.id === item.estabelecimento.value)[0]
        if (entity) {
          item.estabelecimento.text = `(${entity.cod}) - ${entity.name}`
        }


        const entryType = entryTypes.data.resultMap.filter(subItem => subItem.id === item.typeOperation.value)[0]
        if (entryTypes) {
          item.typeOperation.text = entryType.description
          item.typeOperation.type = entryType.type
        }
      })


      this.lancamentoFinanceiro.type = e.params.type
      this.lancamentoFinanceiro.typeOperation = this.selectsTiposOperacoes.filter(item => {
        if (e.params.type === 'Débito') {
          return item.value === 'CD'
        }

        return item.value === 'CC'
      })[0]

      this.loadingModal = false


      // this.setItem(e)

      
    })
    // this.obterItensSelect()
  },

  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters(namespaceStore, ['lancamentoFinanceiro', 'lancamentoFinanceiroIndividual', 'arrayLancamentoFinanceiroIndividual', 'item']),
    ...mapGetters('tiposOperacoes', {
      listaItensSelectsTiposOperacoes: 'listaItensSelect'
    }),
    ...mapGetters('entities', ['listaItensSelect'])
  },

  destroyed () {
    Events.$off('cadastro::openModalCadastro') 
  },

  methods: {
    ...mapActions(namespaceStore, ['cadastrarItem', 'limparItem', 'getItens', 'editarItem', 'addLancamentoFinanceiroIndividual', 'setLancamentoParaEditar', 'removeLancamentoParaEditar', 'setItem']),
    ...mapActions('entities', {
      obterItensSelect: 'obterItens',
      getEntities: 'getEntities'
    }),
    ...mapActions('tiposOperacoes', {
      getItensTiposOperacoes: 'getItens'
    }),

    ...mapActions('entryTypes', {
      getItensEntryTypes: 'getItens'
    }),

    formatDate (date) {
      if (!date) return null
      return moment(date).format('DD/MM/YYYY')
    },

    submit () {
      if (this.validacaolocal.$invalid && this.arrayLancamentoFinanceiroIndividual.length < 1) {
        return false
      }

      this.loadingBtn = true

      const dados = {
        entityId: this.lancamentoFinanceiro.credenciador.value,
        pendingOperationTypeId: 'LCX',
        // params: this.arrayLancamentoFinanceiroIndividual,
        params: {
          type: this.getTypeOperationParams(this.lancamentoFinanceiro.value),
          data : this.arrayLancamentoFinanceiroIndividual.map(item => {
            return {
              entity: {
                id: item.estabelecimento.value
              },
              value: item.value,
              entryType: {
                id: item.typeOperation.value
              }
            }
          })
        },
        value: this.lancamentoFinanceiro.value
      }

      if (this.lancamentoFinanceiro.id) {
        dados.id = this.lancamentoFinanceiro.id
        dados.status = 'N'

        this.editarItem(dados).then(() => {
          this.getItens()
          this.closeModal()
          this.loadingBtn = false
          successSnackbar('Edição realizado com sucesso!')
        }).catch(err => {
          this.loadingBtn = false
          errorSnackbar(err)
        })
      } else {
        this.cadastrarItem(dados).then(() => {
          this.getItens()
          this.closeModal()
          this.loadingBtn = false
          successSnackbar('Cadastro realizado com sucesso!')
          
        }).catch(err => {
          this.loadingBtn = false
          errorSnackbar(err)
        })
      }
    },

    getTypeOperationParams (valor) {
      return valor < 0 ? 'Débito' : 'Crédito'
    },

    proximo (validacao) {
      if (validacao.$invalid) {
        validacao.$touch()
        return false
      }

      this.tab = 'tab-2'
    },

    validacao (validacao) {
      this.validacaolocal = validacao
    },

    validacaoIndividual (validacao) {
      this.validacaoIndividualLocal = validacao
    },

    closeModal () {
      this.limparItem()
      // this.validacaolocal.$reset()
      // this.validacaoIndividualLocal.$reset()
      this.activeModal = false
      this.tab = 'tab-1'
    },

    async openModal () {
      // this.loading = true
      this.activeModal = true
      await Promise.all([
        
      ])
      .then(() => {
      })
      .catch(() => {
        // errorSnackbar(e)
      })
      .finally(() =>{
        this.loading = false
      })
    },

    getEntitiesForDetalhamento () {
      
    }
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/formularios';
  // @import '~@/assets/styles/components/modal-cadastro';
</style>