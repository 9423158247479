import variables from '@/assets/styles/helpers/_variables.scss'
import { mapGetters } from 'vuex'

export default  {
  data: () => ({
    loading: false
  }),

  computed: {
    ...mapGetters('entities', ['listEntityTypes']),
    variables: () => variables,
    isEdit () {
      return this.item.id ? !!1 : !!0
    }
  },

  methods: {
   getEntityType (id) {
    let str = this.listEntityTypes.filter(item => item.id === id)[0].text
    str = str.toLowerCase()
    str = str.charAt(0).toUpperCase() + str.substr(1)

    return str
   }
  },
}