<template>
  <v-form>
    <v-card-title class="modal-cadastro-titulo">
      Credenciador: {{ lancamentoFinanceiro.credenciador.text }} 
    </v-card-title>

    <v-card-text class="modal-cadastro-form">
      <v-row>
        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3">
          <autoCompleteEntities
          :label="getEntityType(4)"
          :entityProps.sync="lancamentoFinanceiroIndividual.estabelecimento"
          :typesEntities="['4']"
          :parentId="lancamentoFinanceiro.credenciador.value"
          :error-messages="estabelecimentioErrors" />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3">
          <v-autocomplete
            v-model="lancamentoFinanceiroIndividual.typeOperation"
            :items="listEntryTypesSelect"
            :error-messages="typeOperationErrors"
            :loading="loadingEntryTypes"
            return-object
            dense />
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2">
        <!-- <VCurrencyField 
          v-model.number="lancamentoFinanceiro.value"
          :allowNegative="true"
          :error="valueErrors"
          @input="$v.lancamentoFinanceiro.value.$touch()" 
          @blur="$v.lancamentoFinanceiro.value.$touch()"
          dense /> -->

          <v-currency-field
            v-model="lancamentoFinanceiroIndividual.value"
            label="Valor"
            prefix="R$"
            :error-messages="valueErrors"
            dense/>
        </v-col>

        <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3">
          <v-btn :color="variables.colorSecondary" @click.prevent="clear" class="mr-2" dark>Limpar</v-btn>
          <v-btn :color="variables.colorPrimary" @click.prevent="add" dark>Add</v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="arrayLancamentoFinanceiroIndividual"
        :loading="loadingTable"
        width="300px"
        height="auto"
        flat
        dense
        :items-per-page="10">
        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="(item, index) in items"
              :key="index"
            >
              <td>{{ item.estabelecimento.text }}</td>
              <td>{{ item.typeOperation.text }}</td>
              <td>{{ item.value | currency }}</td>
              <td>
                <v-icon
                  small
                  class="mr-2"
                  @click="editar(item, index)"
                >
                  edit
                </v-icon>
                <v-icon
                  small
                  @click="deletar(index)"
                >
                  delete
                </v-icon>
              </td>

            </tr>

            <tr>
              <th colspan="2">Total</th>
              <th>{{ lancamentoFinanceiroIndividualTotal | currency }}</th>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card-text>        

    <v-card-actions class="modal-cadastro-footer">
      <v-btn :color="variables.colorPrimary" @click.prevent="submit" class="br-btn" :loading="loadingBtn">Salvar</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, minValue } from 'vuelidate/lib/validators'
import { currency } from '@/core/service/utils'
// import moment from 'moment'
// import VCurrencyField from '@/views/components/vCurrencyField'
import autoCompleteEntities from '@/views/components/autoCompleteEntities'

import validacao from './validacaoLancamentoFinanceiroIndividual'
const namespaceStore = 'lancamentoFinanceiro'


/**
 * MIXIN
 */
import modalCadastro from '@/views/mixins/modalCadastro'
// import autocomplete from '@/views/mixins/autocomplete'


export default {
  name: 'ItensTabsModalLancamentoFinanceiroIndividual',
  mixins: [validationMixin, modalCadastro],
  // directives: {mask},
  props: {
    loadingBtn: {
      type: Boolean,
      default: false
    }
  },
  components: { 
    autoCompleteEntities
   },
  data: () => ({
    date: null,
    dateFormatted: null,
    menuDate: false,
    activeModal: false,
    searchText: null,
    isLoadingAutoComplete: false,
    modelAutoComplete: null,
    loadingEntryTypes: false,
    loadingTable: false,

    headers: [
      { class: 'table-header', text: 'Estabelecimento', value: 'estabelecimento.name' },
      { class: 'table-header', text: 'Tipo Operacao', value: 'typeOperation' },
      { class: 'table-header', text: 'Valor', value: 'value' },
      { class: 'table-header', text: '', value: 'action', sortable: false },
    ]
  }),

  mounted () {
    this.$emit('validacao', this.$v.lancamentoFinanceiroIndividual)
    this.loadingEntryTypes = true
    this.getItensEntryTypes({ path:  this.lancamentoFinanceiro.typeOperation.value })
      .finally(() => this.loadingEntryTypes = false)
  },

  watch: {
    'lancamentoFinanceiro.typeOperation.value' (val) {
      this.loadingEntryTypes = true
      this.getItensEntryTypes({ path:  val })
        .finally(() => this.loadingEntryTypes = false)
    }
  },

  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters(namespaceStore, ['lancamentoFinanceiro', 'lancamentoFinanceiroIndividual', 'item', 'arrayLancamentoFinanceiroIndividual']),
    ...mapGetters('entryTypes', {
      listEntryTypesSelect: 'listaItensSelectPaths'
    }),
    ...mapGetters('entities', ['listaItensSelect']),
    ...validacao,

    lancamentoFinanceiroIndividualTotal () {
      return this.arrayLancamentoFinanceiroIndividual.reduce((acc, next) => {
        if (next.typeOperation.type === 'D') {
          return parseFloat(acc.toFixed(2)) - parseFloat(next.value)
        }
        
        return parseFloat(acc.toFixed(2)) + parseFloat(next.value)
      },0)
    },

    listEntryTypesSelect2 () {
      return this.listEntryTypesSelect
    },

    // selectsTiposOperacoes () {
    //   return [
    //     {
    //       text: 'Crédito',
    //       value: 'CC'
    //     },{
    //       text: 'Débito',
    //       value: 'CD'
    //     }
    //   ]
    // }
  },

  methods: {
   ...mapActions(namespaceStore, ['cadastrarItem', 'limparItem', 'getItens', 'editarItem', 'addLancamentoFinanceiroIndividual', 'setLancamentoParaEditar', 'removeLancamentoParaEditar', 'limparFormLancamentoFinanceiroIndividual']),
    ...mapActions('entities', {
      obterItensSelect: 'obterItens'
    }),
    ...mapActions('tiposOperacoes', {
      getItensTiposOperacoes: 'getItens'
    }),

    ...mapActions('entryTypes', {
      getItensEntryTypes: 'getItens'
    }),

    valorTotalLancamentoFinanceiro (lancamentoFinanceiro) {
      // if (lancamentoFinanceiro.typeOperation.value === 'CD') return parseFloat(lancamentoFinanceiro.value) * -1
      // if (lancamentoFinanceiro.typeOperation.value[0] === 'A.R.PGTR') return parseFloat(lancamentoFinanceiro.value) * -1

      return lancamentoFinanceiro.value
    },


    submit () {
      // if (this.$v.lancamentoFinanceiro.$invalid) { 
      //   this.$v.lancamentoFinanceiro.$touch()
      //   return false 
      // }

      if (parseFloat(this.valorTotalLancamentoFinanceiro(this.lancamentoFinanceiro)) !== parseFloat(this.lancamentoFinanceiroIndividualTotal)) {
        this.$swal({
          icon: 'error',
          text: `Valor total do lançamento financeiro de ${currency(this.valorTotalLancamentoFinanceiro(this.lancamentoFinanceiro))}, não confere com o valor total do detalhamento de ${currency(this.lancamentoFinanceiroIndividualTotal)}!`,
          showConfirmButton: false,
        })

        return false
      }

      if (this.arrayLancamentoFinanceiroIndividual.length < 1) {
        this.$swal({
          icon: 'error',
          text: `Lista vazia`,
          showConfirmButton: false,
        })
        return false
      }

      this.$emit('salvarDados')
     
    },
    
    add () {
      if (this.$v.lancamentoFinanceiroIndividual.$invalid) {
        this.$v.lancamentoFinanceiroIndividual.$touch()
        return
      }

      this.addLancamentoFinanceiroIndividual(this.lancamentoFinanceiroIndividual)
      this.$v.lancamentoFinanceiroIndividual.$reset()
    },

    editar (item, index) {
      this.setLancamentoParaEditar({ item, index })
    },

    deletar (item, index) {
      this.removeLancamentoParaEditar(index)
    },

    clear () {
      this.limparFormLancamentoFinanceiroIndividual()
    }

  },

  destroyed () {
  },

  validations () {
    return  {
      lancamentoFinanceiroIndividual: {
        // entity: { required },
        estabelecimento: {
          value: {
            required
          } 
        },
        typeOperation: { required },
        value: { 
          required,
          minValue: minValue(0)
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>