export default {
  estabelecimentioErrors () {
    const errors = []
    if (!this.$v.lancamentoFinanceiroIndividual.estabelecimento.value.$dirty) return errors
    !this.$v.lancamentoFinanceiroIndividual.estabelecimento.value.required && errors.push('Estabelecimento é obrigatório')
    return errors
  },

  typeOperationErrors () {
    const errors = []
    if (!this.$v.lancamentoFinanceiroIndividual.typeOperation.$dirty) return errors
    !this.$v.lancamentoFinanceiroIndividual.typeOperation.required && errors.push('Tipo de operação é obrigatório')
    return errors
  },

  valueErrors () {
    const errors = []
    if (!this.$v.lancamentoFinanceiroIndividual.value.$dirty) return errors
    !this.$v.lancamentoFinanceiroIndividual.value.required && errors.push('Valor é obrigatório')
    !this.$v.lancamentoFinanceiroIndividual.value.minValue && errors.push('Valor mão pode ser negativo')
    return errors
  }
}
